<template>
  <div class="notfound" id="notfound">
    <Nav/>
      <main role="main" id="main">
        <section id="lead">
            <h2>ページが見つかりません</h2>
            <div class="box">
              <p>ページが見つかりません</p>
            </div>
          </section>
      </main>
    <Foot/>
  </div>
</template>

<script>
import Foot from '@/components/Foot.vue'
import Nav from '@/components/Nav.vue'

export default {
  name: 'NotFound',
  components: {
    Foot,
    Nav
  }
}
</script>

<style lang="scss" scoped>
  p,
  ul {
      font-family: 'NuKinakoMochi-BarVtE-1'
  }
  .box {
    height: 100vh;
  }
</style>
